.card {
    width: 400px;
    height: 310px;
    min-width: 400px;
    min-height: 310px;
    cursor: default;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    & a {
        cursor: default;
        & img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 40%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 20%;
        }
        & .siteDist {
            margin-left: 15px;
            margin-top: -35px;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;
            padding: 5px;
            display: flex;
            width: 110px;
            height: 25px;
            z-index: 200;
            & .distBox {
                align-items: center;
                justify-content: center;
                padding: 5px;
                border-radius: 15px;
                display: flex;
                gap: 0;
                width: 110px;
                height: 25px;
                background-color: rgba(255, 255, 255, 0.8);
                box-shadow: black 0 0 5px 0;
                & p {
                    font-size: 0.75rem;
                    margin-top: 15px;
                }
            }
        }
        & .stopsBox {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            left: 275px;
            top: -45px;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 15px;
            box-shadow: black 0 0 5px 0;
            width: 110px;
            height: 25px;
            & p {
                font-size: 0.75rem;
                margin-top: 15px;
            }
        }
        & h1 {
            font-size: 1.25rem;
            text-align: left;
            padding-left: 15px;
            padding-right: 15px;
        }
        & p {
            font-size: 0.9rem;
            text-align: left;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
        }
        & span {
            width: 400px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & .starloc {
                height: 20px;
                align-self: center;
                margin-bottom: 15px;
                margin-left: 0;
            }
        }
        & a {
            text-decoration: none;
            background-color: #DA535D;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 15px;
            margin-bottom: 15px;
            justify-content: space-evenly;
            width: 6.5rem;
            height: 2rem;
            border-radius: 2rem;
            float: right;
            & p {
                margin-top: 12px;
                margin-right: -20px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    & .card {
        padding-left: 0;
        padding-right: 0;
        width: 100px;
        height: 150px;
        min-width: 100px;
        min-height: 150px;
        & a {
            & img {
                border-radius: 10px;
                height: 75%;
                width: 100%;
            }
            .stopsBox {
                display: none;
            }
            & .siteDist {
                position: relative;
                z-index: 2;
                width: 72.5%;
                & .distBox {
                    display: flex;
                    & .distPin {
                        position: absolute;
                        left: 10px;
                    }
                    & p {
                        display: inline;
                        text-align: center;
                        font-size: 0.65rem;
                        position: absolute;
                        left: 12px;
                        width: 67px;
                    }
                }
            }
            & h1 {
                font-size: 0.7rem;
                position: relative;
                margin-top: -8px;
                text-align: center;
                height: 37px;
                padding: 0;
            }
            & p {
                display: none;
            }
            & a {
                display: none;
            }
            & span {
                display: none;
            }
        }
    }
}