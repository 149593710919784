/* Trail Detail Page */
.trailPage {
    display: flex;
    justify-content: space-between;
}
.trailInfo {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.trailHeader {
    height: 22.5vh;
    overflow: scroll;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    padding: 20px;
    & h2 {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: left;
        color: #432A47;
        margin-bottom: 2.5%;
    }
}
.trailPreview {
    height: 45vh;
    overflow: scroll;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    padding: 20px;
    & h2 {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: left;
        color: #432A47;
        margin-bottom: 2.5%;
    }
}
.siteOnTrail {
    background-color: #F8F8F8;
    box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.5);
    border-radius: 10px;
    padding: 10px;
    height: 180px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    & h3 {
        font-size: 1.25rem;
        font-weight: 600;
        text-align: left;
        color: #432A47;
    }
    & img {
        align-self: center;
        min-width: 160px;
        width: 160px;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
    }
    & p {
        font-size: 0.9rem;
        font-weight: 400;
        text-align: left;
        color: #432A47;
    }
    & span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
    }
    & a {
        text-decoration: none;
        background-color: #DA535D;
        color: white;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        margin-top: 10px;
        justify-content: space-evenly;
        width: 6.5rem;
        height: 2rem;
        min-height: 2rem;
        border-radius: 2rem;
    }
}
.trailMap {
    width: calc(55% - 20px);
    height: calc(67.5vh + 20px);
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
}
.trailRating {
    display: flex;
    flex-direction: row;
    gap: 10px;
    & .property {
        padding: 10px;
        height: 30px;
        width: 100px;
        font-size: 0.9rem;
    }
    & .cta {
        box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    }
}
.leaflet-routing-container, .leaflet-bar, .leaflet-routing-collapsible, .leaflet-control {
    display: none;
}

@media screen and (max-width: 900px) {
    .siteOnTrailMobile {
        & img {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            z-index: 0;
        }
        & .siteDescription {
            width: 100vw;
            min-height: 40vh;
            padding: 40px 20px;
            & p {
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
    .trailMap {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
    }
    .trailPagination {
        & #pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            & h2 {
                font-weight: 600;
                font-size: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 70%;
                white-space: nowrap;
            }
        }
        width: 90%;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(4px);
        color: #432A47;
        box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 5px;
        height: 60px;
        position: fixed;
        z-index: 10;
        top: 20px;
        margin-left: 5%;
        margin-right: 5%;
    }
    .trailControls {
        width: 90%;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(4px);
        color: #432A47;
        box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        height: 60px;
        position: fixed;
        z-index: 10;
        bottom: 120px;
        margin-left: 5%;
        margin-right: 5%;
    }
    .audioControls {
        display: flex;
        gap: 10px;
    }
}