.form-box{
    width: 600px; 
    height: 300px; 
    border: 1px solid #ccc; 
    padding: 10px;
    display: flex;
    margin: auto;
}

.textbox{
    width: 100%;
}

.label1{
    padding-right: 10px;
    padding-left: 5px;
}

.submit{
        background-color: #DA535D;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 6.5rem;
        height: 2rem;
        border-radius: 2rem;
       

}

.feedback-button{
	background-color: #DA535D;
    color: white;
    display: flex;
	position: absolute;
    align-items: center;
	bottom:50;
	right: 0;
    justify-content: space-evenly;
    width: 6.5rem;
    height: 2rem;
    border-radius: 2rem;
}

p {
    padding: 3px;
}