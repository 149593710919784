/* Login Page */
.loginPage {
    position: fixed;
    & .bgImg {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        object-position: 50% 50%;
    }
    & .loginForm {
        position: fixed;
        width: 400px;
        height: 300px;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(2px);
        box-shadow: rgba(0,0,0,0.5) 0 0 30px 0;
        border-radius: 20px;
        top: calc(50vh - 150px);
        left: calc(50vw - 200px);
        & h1 {
            font-size: 1.75rem;
            font-weight: 600;
            text-align: center;
            color: #432A47;
            margin-top: 2.5%;
        }
        & p {
            padding: 20px;
        }
    }
    & .hImgCaption {
        position: absolute;
        display: inline;
        bottom: 0px;
        margin-left: 10px;
        height: 2rem;
        padding: 0.3rem 1.2rem 0;
        background-color: rgba(255,255,255,0.75);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 0.9rem;
        font-style: italic;
        & span {
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
            & p {
                display: inline-block;
                margin-bottom: 15px;
            }
        }
    }
    & .login {
        width: 80%;
        padding-top: 20px;
        margin-left: 10%;
        margin-right: 10%;
        & input {
            background-color: rgba(0,0,0,0);
            color: #432A47;
            font-family: 'Inter', sans-serif;
            border: none;
            border-bottom: 2px solid #432A47;
            padding: 10px;
            margin-top: 10px;
            margin-bottom: 20px;
            width: 100%;
        }
        & button {
            color: white;
            width: 40%;
            height: 30px;
            border-radius: 10px;
            margin-left: 30%;
            margin-right: 30%;
            background-color: #432A47;
            cursor: pointer;
        }
        & .loginAlert {
            padding: 0.75rem;
            margin-top: -1rem;
        }
        & .error {
            background-color: rgba(255, 69, 0, 0.5);
            padding: 0.2rem;
            margin-top: -0.5rem;
            border-radius: 10px;
            border: 1px solid orangered;
            font-size: 0.8rem;
            text-align: center;
        }
    }
}