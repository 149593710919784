.hero {
    width: 100%;
    height: 45vh;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & h1 {
        position: relative;
        top: -8vh;
        text-align: center;
        background-color: rgba(0,0,0,0);
        font-size: 2rem;
        font-weight: 600;
        color: white;
        text-shadow: 0px 0px 10px #000000;
        text-transform: uppercase;
    }
}

.editOptions {
    width: 100%;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.cta {
    background-color: #DA535D;
    color: white;
    align-items: center;
    width: 6.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2rem;
    border-radius: 2rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
}

.pgBody {
    padding-top: 60px;
    padding-left: 125px;
    padding-right: 125px;
    margin-bottom: 30px;
    & .path {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    & span {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        & div {
            width: 50%;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
        }
        & .imgGallery {
            & .carousel-root {
                width: 100%;
                padding: 0;
                box-shadow: none;
                border-radius: 0;
                & .carousel {
                    padding: 0;
                    box-shadow: none;
                    border-radius: 0;
                    & .thumbs-wrapper {
                        display: none;
                    }
                }
                & .carousel-slider {
                    padding: 0;
                    box-shadow: none;
                    border-radius: 0;
                    & .control-arrow {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                    & .control-dots {
                        display: none;
                    }
                    & .slider-wrapper {
                        padding: 0;
                        box-shadow: none;
                        border-radius: 0;
                        & .slide {
                            padding: 0;
                            box-shadow: none;
                            border-radius: 0;
                            & div {
                                width: 100%;
                                & p {
                                    background-color: rgba(0, 0, 0, 0.5);
                                    backdrop-filter: blur(4px);
                                    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.siteMap {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    z-index: 0;
    & .leaflet-control-container {
        & .leaflet-bottom, .leaflet-right {
            & .leaflet-touch, .leaflet-control-attribution {
                display: block;
                font-family: 'Inter', sans-serif;
                color: #432A47;
                border-top-left-radius: 5px;
                & a {
                    text-decoration: none;
                    color: #DA535D;
                }
                & span {
                    display: inline;
                }
            }
        }
    }
}

.siteInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & audio {
        width: 100%;
    }
}

.imgGallery {
    & .carousel {
        position: relative;
        width: 100%;
        height: 100%;
        & ul {
            height: 100%;
            & li {
                & img {
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                }
            }
        }
    }
}

.slide[data-active]{
    opacity: 1;
}

.carousel > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.carousel-button {
    position: absolute;
    z-index: 2;
    border: none;
    font-size: 4rem;
    top: 50%;
    transform: translateY(-50);
    color: rgba(243, 239, 239, 0.5);
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
}

.carousel-button:hover,
.carousel-button:focus{
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
}

.carousel-button.prev {
    left: 1rem;
}

.carousel-button.next {
    right: 1rem;
}

.formCard {
    width: 100%;
    background-color: white;
    & span{
        margin-bottom: 20px;
        margin-top: 8px;
        display: flex;
        align-items: center;
    }
}

.label2 {
    padding-left: 5px;
}


@media screen and (max-width: 900px) {
    .hero {
        height: 30vh;
        & h1 {
            padding: 0 20px;
            font-size: 1.25rem;
        }
    }

    .pgBody {
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        & span {
            padding-bottom: 80px;
            & div {
                width: 100%;
            }
        }
    }

    .path {
        display: none;
    }

    .editOptions {
        display: none;
    }

    span {
        display: flex;
        flex-direction: column;
    }
}