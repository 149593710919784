/* Home Page */
.homepage {
    & .hero {
        display: inline;
        & .mobileLogo {
            display: none;
        }
        & img {
            height: 70vh;
            width: 100vw;
            object-fit: cover;
            object-position: 50% 20%;
        }
        & .hImgCaption {
            position: absolute;
            display: inline;
            top: calc(70vh - 2rem);
            margin-left: 10px;
            height: 2rem;
            padding: 0.3rem 1.2rem 0;
            background-color: rgba(255,255,255,0.75);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 0.9rem;
            font-style: italic;
            & span {
                display: flex;
                justify-content: center;
                gap: 10px;
                align-items: center;
                & p {
                    display: inline-block;
                    margin-bottom: 15px;
                }
            }
        }
        & h1 {
            position: absolute;
            top: calc(70vh - 7.5rem);
            width: 100vw;
            font-size: 2.25rem;
            font-weight: 600;
            text-align: center;
            color: white;
            text-shadow: 0px 0px 20px #000000;
            & b {
                font-weight: 800;
            }
        }
    }

    & .subpage {
        min-height: 40vh;
    }
}

.section {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & span {
        display: flex;
        justify-content: space-between;
        & h2 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #432A47;
        }
        & a {
            display: flex;
            align-items: center;
            gap: 10px;
            & button {
                font-size: 0.9rem;
            }
        }
    }
    & .carousel {
        display: flex;
        overflow: scroll;
        gap: 20px;
    }
}

@media screen and (max-width: 900px) {
    /* Home Page */
    .homepage {
        /* Adjusting Hero backer at top of page to include a mobile site logo. */
        & .hero {
            & .mobileLogo {
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: absolute;
                font-size: 1.5rem;
                width: 100%;
                margin-top: 1.25rem;
                color: #432A47;
                text-shadow: 0px 0px 30px #000000;
                & .upper {
                    font-weight: 500;
                }
                & .lower {
                    font-weight: 300;
                    font-style: italic;
                }
            }
            & img {
                height: 50vh;
                width: 100vw;
                object-fit: cover;
                object-position: 50% 20%;
            }
            & .hImgCaption {
                top: calc(50vh - 1.5rem);
                background-color: rgba(0,0,0,0);
                color: white;
                text-shadow: 0px 0px 10px #000000;
                margin-left: 20%;
                margin-right: 20%;
                width: 60%;
                height: 1.5rem;
                font-size: 0.75rem;
                & span {
                    gap: 10px;
                    & p {
                        margin-bottom: 25px;
                    }
                }
                & .hCaptionIcon {
                    display: none;
                }
            }
            & h1 {
                font-size: 1.25rem;
                top: calc(50vh - 5rem);
            }
        }
    }

    /* Homepage Sections */
    .section {
        & span {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & h2 {
                font-size: 0.9rem;
                text-transform: uppercase;
                font-weight: 600;
                color: #432A47;
            }
            & a {
                display: flex;
                align-items: center;
                gap: 5px;
                & button {
                    font-size: 0.9rem;
                }
            }
        }
        & .carousel {
            height: 155px;
            display: flex;
            overflow: scroll;
            gap: 10px;
            margin-bottom: 0;
        }
    }
}