.content-manager {
    & h1 {
        text-align: left;
    }
}

.cms-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & h1 {
        margin: 0;
    }
}

.account-controls {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    & .cta {
        box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    }
}

.cms-container {
    display: flex;
    width: 100%;
    height: 68vh;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
}

.cms-container-vertical {
    width: 50%;
    height: 68vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.list-container {
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    width: 50%;
    padding: 20px;
    background-color: white;
    & span {
        display: flex;
        justify-content: space-between;
        & h2 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #432A47;
        }
        & .link {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #432A47;
            & p {
                font-size: 0.8rem;
                text-transform: uppercase;
            }
        }
    }
}

.engagement {
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    width: 100%;
    height: 33vh;
    padding: 20px;
    background-color: white;
    & span {
        display: flex;
        justify-content: space-between;
        & h2 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #432A47;
        }
        & .link {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #432A47;
            & p {
                font-size: 0.8rem;
                text-transform: uppercase;
            }
        }
    }
}

.feedback-container {
    /*overflow-y: auto;*/
    max-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding-top: 20px;
}

.feedback-card {
    /*box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);*/
    padding: 15px;
    width: 33%;
    height: 22vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #d0d0d0;
    & h2 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #432A47;
        text-align: center;
    }
    & p {
        text-align: center;
    }
}

.feedback-icon {
    width: 100px;
    height: 100px;
    color: #DA535D;
    align-self: center;
}

/*.feedback-comment {*/
/*    margin: 0px;*/
/*    font-size: 15px;*/
/*}*/

/*.feedback-date {*/
/*    margin-top: 2px;*/
/*    font-size: 12px;*/
/*    color: #888;*/
/*}*/

.trail-list {
    margin-top: 15px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 50px);
}

.trail-card {
    display: flex;
    align-items: center;
    gap: 20px;
    min-height: 150px;
    height: 150px;
    width: 100%;
    padding: 15px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
    background-color: #fafafa;
    & img {
        width: 125px;
        min-width: 125px;
        height: 125px;
        min-height: 125px;
        object-fit: cover;
        border-radius: 10px;
    }
    & span {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        & h3 {
            font-size: 1.2rem;
            font-weight: 600;
            color: #432A47;
        }
    }
}

.trail-details {
    & p {
        font-size: 0.9rem;
        color: #432A47;
    }
}

.trail-controls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.trail-edit {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.trail-feedback {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.site-list {
    margin-top: 15px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 10px;
    height: 85%;
}

.site-card {
    min-width: 160px;
    max-width: 160px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    & h1 {
        font-size: 1rem;
        font-weight: 600;
        color: #432A47;
        text-align: center;
    }
    & img {
        height: 50%;
        max-height: 50%;
        min-height: 50%;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
}

/*.site-card {*/
/*    height: 170px;*/
/*    background-color: #FAFAFA;*/
/*    border-radius: 10px;*/
/*    border: 1px solid #d0d0d0;*/
/*    & img {*/
/*        width: 100%;*/
/*        height: 40%;*/
/*        padding: 2px;*/
/*        border-radius: inherit;*/
/*    }*/
/*    & h1 {*/
/*        padding-top: 10px;*/
/*        font-size: 0.9rem;*/
/*    }*/
/*    & p {*/
/*        padding: 5px;*/
/*        font-size: 0.8rem;*/
/*        text-align: center;*/
/*    }*/
/*    & .btn {*/
/*        width: 40px;*/
/*    }*/
/*}*/

.site-feedback {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.site-controls {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.attribute {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    border-radius: 20px;
    background-color: white;
    & p {
        font-size: 0.8rem;
        text-transform: uppercase;
    }
}

.btn {
    background-color: #DA535D;
    color: white;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    box-shadow: 0 0 10px 0 rgba(128, 128, 128, 0.5);
    border-radius: 20px;
    & p {
        font-size: 0.8rem;
    }
}

.upload-form {
    background-color: white;

    /* use flexbox in span tag in the form, this allows us to display each input field in a column! */
    & span {
        display: flex;
        gap: 20px;
        flex-direction: column;

        & input {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border: none;
        }

        & select {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border: none;
        }
    }
}

/* Styling for the submit button at the bottom of the form, keeping it in line with the rest of the app. */
.submitBtn {
    background-color: #DA535D;
    width: 20%;
    align-self: center;
    color: white;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        background-color: #C23C4D;
    }
}

.site-upload-map {
    width: 70%;
}

.input-file {
    display: flex;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: none;
    & p {
        width: 200px;
        align-self: center;
        padding-left: 15px;
        font-size: 0.9rem;
    }
    & input {
        width: 100%;
        align-self: center;
        border: none;
        box-shadow: none;
        border-radius: 10px;
    }
}
.select-sites {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: none;
    padding: 15px;
}
.side1 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    & span {
        display: flex;
        flex-direction: row;
        gap: 12px;
        & span {
            display: flex;
            width: 50%;
            flex-direction: column;
            gap: 12px;
        }
    }

    & input {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border: none;
    }
    & textarea {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border: none;
        border-radius: 10px;
    }
}
.site-upload-map {
    & .osm {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    width: 60%;
}
.audio-upload {
    padding: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    & h2 {
        margin-bottom: 10px;
    }
    & span {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        & audio {
            width: 100%;
        }
        & button {
            width: 170px;
            height: 40px;
            background-color: #DA535D;
            color: white;
            border: none;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2s;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & p {
                font-size: 0.9rem;
            }
            &:hover {
                background-color: #C23C4D;
            }

        }
    }
}
.sites-on-trail {
    padding: 15px;
    border-radius: 10px;
    height: 200px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    & .itinerary {
        overflow: scroll;
        height: 140px;
    }
}
.itinerary {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.site {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    height: 120px;
    width: 130px;
    min-width: 130px;
    background-color: #FAFAFA;
    border-radius: 10px;
    border: 1px solid #d0d0d0;
    gap: 10px;
    & img {
        width: 40px;
        height: 40px;
        border-radius: 5px;
    }
    & p {
        font-size: 0.7rem;
        text-align: center;
    }
    & button {
        width: 20px;
        height: 20px;
        justify-self: flex-end;
    }
}

.img-gallery {
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    & h2 {
        margin-bottom: 10px;
    }
}

.gallery {
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 140px;
}

.img-card {
    width: 120px;
    height: 135px;
    background-color: #fafafa;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 10px;

    & img {
        border-radius: 5px;
        width: 100%;
        height: 70px;
        object-fit: cover;
    }
}

.add-card {
    width: 120px;
    height: 135px;
    background-color: #fafafa;
    border: 1px dashed #d0d0d0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 10px;
}

.app-feedback-modal {
    height: 500px;
    & table {
        width: 100%;
        & th {
            background-color: #432A47;
            color: white;
            padding: 10px;
            text-align: left;
        }
        & td {
            padding: 10px;
            border-bottom: 1px solid #d0d0d0;
        }
    }
}

.manage-users-modal {
    display: flex;
    gap: 10px;
    flex-direction: column;
    & table {
        width: 100%;
        & th {
            background-color: #432A47;
            color: white;
            padding: 10px;
            text-align: left;
        }
        & td {
            padding: 10px;
            border-bottom: 1px solid #d0d0d0;
            font-size: 0.9rem;
        }
    }
    & button {
        background-color: #DA535D;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        align-self: flex-end;
        gap: 10px;
        &:hover {
            background-color: #C23C4D;
        }
        & p {
            text-align: right;
        }
    }
}

.site-feedback-table {
    width: 100%;
    & th {
        background-color: #432A47;
        color: white;
        padding: 10px;
        text-align: left;
    }
    & td {
        padding: 10px;
        border-bottom: 1px solid #d0d0d0;
    }
}

.content-feedback-modal {
    height: 500px;
}

@media screen and (max-width: 1360px) {
    .trail-card {
        & .trail-controls {
            & .trail-feedback {
                display: none !important;
            }
        }
    }
}