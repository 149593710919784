/* Maps Page */
.map-container {
    height: 100%;
    z-index: 0;
    position: fixed;
    width: 100%;
}
.osm {
    & .leaflet-control-container {
        & .leaflet-bottom, .leaflet-right {
            & .leaflet-touch, .leaflet-control-attribution {
                display: block;
                font-family: 'Inter', sans-serif;
                color: #432A47;
                border-top-left-radius: 5px;
                & a {
                    text-decoration: none;
                    color: #DA535D;
                }
                & span {
                    display: inline-block;
                }
            }
        }
    }
}
.leaflet-popup-content-wrapper {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    font-family: 'Inter', sans-serif;
}
.leaflet-popup-tip {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
}
.map-popup {
    font-family: 'Inter', sans-serif;
    max-width: 250px;
    min-width: 250px;
    & h1 {
        font-size: 1rem;
        text-align: center;
        font-weight: bold;
    }
    & p {
        text-align: center;
    }
    & .popup-link {
        font-size: 0.8rem;
        font-weight: 500;
        background-color: #DA535D;
        color: white;
        border-radius: 25px;
        margin-left: 30%;
        margin-right: 50%;
        width: 40%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        & p {
            font-family: 'Inter', sans-serif;
            margin-left: 15%;
        }
    }
    & img {
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
        border-radius: 10px;
        object-fit: cover;
    }
}

.site-attributes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
}

.property {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    height: 20px;
    width: 80px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: white;
}

.circular-icon {
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); 
}

.circular-icon img {
    height: 150%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.circular-icon:hover img {
    transform: scale(1.1); 
}

.search-bar-container {
    position: absolute;
    bottom: 20px;
    left: 30vw;
    right: 30vw;
    width: 40vw;
    height: 50px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.recentre-button {
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: #F8F8F8;
}

.search-bar {
    font-family: 'Inter', sans-serif;
    padding: 5px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
}

/*.search-bar {*/
/*    width: calc(100% - 20px);*/
/*    border: 1px solid #ccc;*/
/*    padding: 10px 15px;*/
/*    border-radius: 10px;*/
/*    font-size: 1rem;*/
/*    color: #333;*/
/*}*/


@media screen and (max-width: 900px) {
    /* Adjusts map container to size of mobile display. */
    .map-container {
        height: 100vh;
        width: 100%;
    }

    .search-bar-container {
        top: 10px;
        left: 15px;
        right: 15px;
        bottom: unset;
        width: calc(100% - 30px);
        padding: 5px;
        height: 50px;
    }

    .recentre-button {
        width: 40px;
        height: 40px;
    }
}