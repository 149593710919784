/* Sites Page */
.sitesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 15px;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.sitesContainer::after {
    content: " ";
    flex-basis: 420px;
}

.sitesContainer {
    & .card {
        & span {
            position: relative;
        }
    }
}

.search-filter-bar {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    & div {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 25%;
    }
    & select {
        font-family: 'Inter', sans-serif;
        padding: 0 35px 0 5px;
        width: 70%;
        border-radius: 20px;
        border: 1px solid #cccccc;
    }
    & input {
        font-family: 'Inter', sans-serif;
        padding: 0 5px 0 5px;
        width: 25%;
        border-radius: 20px;
        border: 1px solid #cccccc;
    }
}

@media screen and (max-width: 900px) {
    /* Adjusting the Sites cards for mobile view. */
    .sitesContainer {
        justify-content: space-between;
        flex-flow: row wrap;
        gap: 12px;
        & .outer {
            padding-left: 0;
            padding-right: 0;
            width: 100px;
            height: max-content;
            & .siteCard {
                width: 100px;
                height: 150px;
                & img {
                    border-radius: 10px;
                    height: 75%;
                    width: 100%;
                }
                & .siteDist {
                    position: relative;
                    z-index: 2;
                    width: 72.5%;
                    & .distBox {
                        display: flex;
                        & .distPin {
                            position: absolute;
                            left: 10px;
                        }
                        & p {
                            display: inline;
                            text-align: center;
                            font-size: 0.65rem;
                            position: absolute;
                            left: 12px;
                            width: 67px;
                        }
                    }
                }
                & h1 {
                    font-size: 0.7rem;
                    position: relative;
                    margin-top: -8px;
                    text-align: center;
                    height: 37px;
                    padding: 0;
                }
                & p {
                    display: none;
                }
                & a {
                    display: none;
                }
                & span {
                    display: none;
                }
            }
        }
    }
    .sitesContainer::after {
        content: " ";
        flex-basis: 100px;
    }

    .search-filter-bar {
        flex-direction: column-reverse;
        gap: 5px;
        width: 100%;
        & div {
            width: 100%;
            & label {
                display: none;
            }
            & select {
                width: 100%;
            }
        }
        & input {
            width: 100%;
        }
    }
}