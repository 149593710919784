@tailwind base;
@tailwind components;
@tailwind utilities;

/**
    ABERDEEN UNCOVERED - STYLESHEET
    TEAM BRAVO - UNIVERSITY OF ABERDEEN 2023
    Contained in this file are the styles used within the application.
 */

/* Global Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Global Styles */
body {
    font-family: 'Inter', sans-serif;
    background-color: #F9F7F3;
}

/* Styling for Subpages */
.subpage {
    padding-top: 90px;
    margin: 0 2.5%;
    min-height: calc(100vh - 78px);
    & h1 {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        color: #432A47;
        margin-bottom: 2.5%;
    }
}

/* Navigation Bar */
.nvb {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 15px;
    position: fixed;
    width: 95%;
    height: 45px;
    margin: 20px 2.5%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    z-index: 10;
    box-shadow: 0 0 15px 0px rgb(128, 128, 128);
    color: #432A47;
    /* Styles for the ABERDEENuncovered Logo shown in menu bar */
    & .logo {
        width: 40rem;
        font-size: 1.25rem;
        & .upper {
            font-weight: 500;
        }
        & .lower {
            font-weight: 300;
            font-style: italic;
        }
    }
    /* Mobile nav bar icons (don't display these unless media query states viewport <= 700px */
    & .nvbIcon {
        display: none;
    }
    /* Menu items in nav bar have the default hyperlink underline removed. */
    & .nbItem {
        text-decoration: none;
    }
    /* Link menu items: */
    & ul {
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 300px;
        /* Credit: https://magento.stackexchange.com/questions/58618/display-horizontal-lines-between-menu-items
           https://jankollars.com/posts/multi-line-horizontal-list-with-separators*/
        & li {
            list-style: none;
            display: inline-block;
            width: 15%;
            font-size: 0.8rem;
            /* Navbar item separators */
            & p {
                border-left: 1px solid rgba(67, 42, 71, 0.25);
                color: #432A47;
            }
        }
        & li:first-child {
            & p {
                border-left: none;
            }
        }
    }
    /* Profile Button and Logout Button */
    & .profile {
        width: 40rem;
        & span {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            & p.nbText {
                border-left: 1px solid rgba(67, 42, 71, 0.25);
                padding-left: 20px;
                font-size: 0.8rem;
                text-align: center;
            }
            & #profileLink {
                font-size: 0.8rem;
                text-align: center;
            }
        }
    }
}

/* Global Alerts Tag - Flowbite */
.globalAlerts {
    position: absolute;
    top: 100px;
    border-radius: 15px;
    margin-left: 67.5%;
    width: 30%;
    box-shadow: 0 0 15px 0 rgb(128, 128, 128);
    z-index: 100;
}

/* Loading Content - Full Page */
.loadingContent {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & p {
        font-size: 0.9rem;
        font-weight: 600;
        text-align: center;
        color: #432A47;
        margin-bottom: 2.5%;
    }
}

.starloc{
    display: flex;
    margin-left: 10px;
}

input[type="radio"]{
    display: none;

  }

.ratingDisplay {
    margin-left: 0px;
}

.version-entry {
    border-bottom: 1px solid rgba(67, 42, 71, 0.1);
    padding: 10px;
    & h1 {
        font-size: 0.7rem;
        font-weight: 600;
        margin-bottom: 5px;
    }
    & ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        & li {
            margin-left: 15px;
            list-style: bullet;
            display: block;
            font-size: 0.9rem;
            color: #432A47;
        }
    }
}
.version-entry:last-child {
    border-bottom: none;
}

/**
    ====================================================================================================================
    MOBILE CSS
    Everything beneath this line adjusts the CSS we have above this line to fit it to a mobile device.
    Comments attached to each media query explain the rationale behind the adjustment.
    ====================================================================================================================
 */

/* Media Queries - adjusts content when page width is <= 700px. */
@media screen and (max-width: 900px) {
    /* Changes NaviBar into mobile-friendly navigation bar. */
    .nvb {
        background-color: rgba(255, 255, 255, 0.8) !important;
        position: fixed;
        margin: 0;
        bottom: 30px;
        width: 90%;
        height: 60px;
        transform: translate(5%, 0);
        border-radius: 30px !important;
        box-shadow: 0 0 15px 0px rgb(128, 128, 128);
        & .nvbIcon {
            display: revert;
            min-height: 60px;
            max-height: 60px;
        }
        & .logo {
            display: none;
        }
        & .profile {
            display: none;
        }
        & ul {
            text-align: center;
            & li {
                list-style: none;
                display: inline-block;
                width: 25%;
                min-height: 40px;
                color: white;
                & p {
                    display: none;
                }
            }
        }
    }

    /* Adjusting subpage styles to suit mobile displays, as per Figma. */
    .subpage {
        padding-top: 5%;
        padding-bottom: 80px;
        margin: 0 5%;

        & h1 {
            margin-top: 30px;
            text-align: left;
            font-size: 2rem;
            font-weight: 600;
            color: #432A47;
            margin-bottom: 2.5%;
        }
    }

    /* Remove the footer on the mobile site as it interferes with nav bar */
    #dtFooter {
        display: none;
    }

    /* Global Alerts Tag - we make this wider for mobile screens. */
    .globalAlerts {
        top: 20px;
        margin-left: 2.5%;
        width: 95%;
        z-index: 100;
    }
}
